<template>
  <div>
    <div class="ctl-table-container">
      <pil-catalog-filters
        @refresh-filters="refreshItemsList"
        @refresh-sort-params="refreshItemsListWithSort"
        :catalogTab="tab"
      />
      <div class="ctl-titles">
        <label class="checkbox-container">
          <input
            type="checkbox"
            hidden
            ref="selectAll"
            @input="updateCheckedItemsList"
          />
          <div class="checkbox-item"></div>
        </label>
        <div class="grid">
          <span
            class="ctl-titles-item"
            v-for="(t, idx) of titlesList"
            :key="idx"
            >{{ t.name }}</span
          >
        </div>
      </div>
      <div class="ctl-items-list">
        <div
          class="ctl-item"
          :class="{ reversed: idx % 2 }"
          v-for="(f, idx) of items"
          :key="idx"
        >
          <label class="checkbox-container">
            <input
              type="checkbox"
              hidden
              :value="f"
              v-model="checkedItemsMod"
            />
            <div class="checkbox-item" :class="{ reversed: idx % 2 }"></div>
          </label>
          <div class="ctl-item-container grid" @click="showEditPopup(f)">
            <!-- status -->
            <div class="ctl-item-param">
              <div
                class="smallbold locale-value locale-value-visible center"
                :class="`status_${f.status}`"
              >
                {{ f.status }}
              </div>
            </div>
            <!-- name -->
            <div class="ctl-item-param">
              <div class="ctl-item-param">
                <div
                  class="smallbold locale-value locale-value-visible center"
                  :title="f.name"
                >
                  <img alt="img" :src="f.preview" class="preview" />
                </div>
              </div>
            </div>
            <!-- size -->
            <div class="ctl-item-param">
              {{ getSizeInMBs(f.size) }}
            </div>
            <!-- create at -->
            <div class="ctl-item-param">
              {{ getCorrectDate(f.created_at).slice(0, 19) }}
            </div>
            <!-- rating -->
            <div class="ctl-item-param">
              <div class="smallbold locale-value locale-value-visible center">
                {{ f.rating }}
              </div>
            </div>
            <!-- content manager -->
            <div class="ctl-item-param">
              <div class="smallbold locale-value locale-value-visible center">
                {{ f.content_manager && f.content_manager.email }}
              </div>
            </div>
            <!-- author -->
            <div class="ctl-item-param">
              <div class="smallbold locale-value locale-value-visible center">
                {{ f.author && f.author.email }}
              </div>
            </div>
            <!-- copyright -->
            <div class="ctl-item-param">
              {{ f.copyright }}
            </div>
            <!-- comment -->
            <div class="ctl-comments-text">
              <div v-for="(c, idx) of f.comment" :key="idx">
                <span class="ctl-comment-email">{{ c.user_email }}</span>
                <span class="ctl-comment-date">
                  ({{ getCorrectDate(c.date) }}):
                </span>
                <span
                  class="ctl-comment-msg"
                  :class="{
                    green: c.user_email !== f.author && f.author.email
                  }"
                  >{{ c.msg }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="ctl-loader">
          <div
            v-infinity-scroll="{
              cb: fetchPathItemsList,
              tab
            }"
            id="scroll-trigger"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PilCatalogFilters from "@/components/catalog/Filters";
import "@/assets/css/catalog.css";
import catalogMixin from "@/mixins/catalogMixin";

export default {
  name: "fontsCatalog",
  props: ["tab"],
  mixins: [catalogMixin],
  components: {
    PilCatalogFilters
  },

  data() {
    return {
      // ? filter select items list
      filters: {},
      sortParams: { created_at: "desc", name: "asc" },
      // ? titles
      titlesList: [
        { name: "status" },
        { name: "name" },
        { name: "size" },
        { name: "created at" },
        { name: "rating" },
        { name: "content manager" },
        { name: "author" },
        { name: "copyright" },
        { name: "comment" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      items: "catalog/fonts"
    })
  }
};
</script>

<style scoped>
.preview {
  max-height: 22px;
}
.ctl-item {
  height: 80px;
}
.grid {
  display: grid;
  white-space: nowrap;
  grid-template-columns:
    minmax(120px, 120fr)
    minmax(250px, 250fr)
    minmax(70px, 70fr)
    minmax(100px, 100fr)
    minmax(200px, 200fr)
    minmax(200px, 200fr)
    minmax(200px, 200fr)
    minmax(150px, 150fr)
    minmax(400px, 400fr);
  grid-gap: 1vw;
  align-items: center;
  justify-items: center;
  min-width: fit-content;
  width: 100%;
}
</style>
